@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3 {
  color: rgb(54, 54, 54);
}

h3,
h4 {
  font-weight: 400;
  color: rgb(100, 100, 100);
}

.App {
  transition: all 0.5s ease;
  height: 100vh;
}

.library-active {
  margin-left: 30%;
}

@import "./song";
@import "./player";
@import "./library";
@import "./nav";
@import "./topPodcast";
@import "./podcastDetail";
