.top-podcasts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  row-gap: 15px;
  column-gap: 15px;
  margin: 15px 15px;
}

.podcast-card {
  display: grid;
  grid-template-rows: max-content 1fr;
  border-radius: 8px;
  box-shadow: 5px 5px 5px rgba(34, 30, 30, 0.602);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.01);
  }
}

.podcast-card img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.podcast-info {
  padding: 10px;
  font-weight: 600;
}
