.podcast-info-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;

  img {
    object-fit: cover;
    width: 300px;
    border-radius: 20px;
    padding: 0px 10px;
    margin: 0px 15px;
  }
}

.podcast-text-wrapper {
  div {
    font-size: 42px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  p {
    font-size: 20px;
    text-align: justify;
  }
}

.episodes-list {
  padding: 10px;
}

.episode-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  margin-bottom: 10px;
}

.play-pause-icon {
  margin-right: 10px;
}

.episode-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.episode-description {
  font-size: 18px;
  p {
    margin-bottom: 5px;
  }
  a {
    text-decoration: none;
    color: black;
  }
  li {
    list-style: none;
  }
}

.date-and-duration {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
  color: rgb(85, 83, 83);
}
.upload-date {
  margin-right: 5px;
}
.duration {
  margin-left: 5px;
}
